import {
  FulfillmentResponse,
  FulfillmentType,
  LeaflyFulfillmentDetails,
  LeaflyItems,
  shippingFulfillmentType,
  Variant,
} from "custom-types/Fulfillment";

export const toTitleCase = (str = "") =>
  str.replace(/./, (m) => m.toUpperCase());

const paymentMethodLabels: Record<string, string> = {
  cash: "Cash",
  credit: "Credit Card",
  debit: "Debit",
  dutchie_pay: "Pay by Bank/DutchiePay",
};

export const getPaymentMethodsString = (paymentMethods: string[]) => {
  const sortedPaymentOptions = paymentMethods
    .map((method) => paymentMethodLabels[method])
    .sort((a, b) => a.localeCompare(b));

  return sortedPaymentOptions.join(", ");
};

export const formatPrice = (price: number) =>
  price.toLocaleString("en-US", {
    currency: "USD",
    maximumFractionDigits: 2,
    minimumFractionDigits: price % 1 === 0 ? 0 : 2, // No decimals for whole numbers
    style: "currency",
  });

export const formatDeliveryFee = (fee?: number | null) => {
  if (fee === undefined || fee === null) {
    return "";
  }
  return Number(fee) === 0 ? "Free" : `${formatPrice(fee)} fee`;
};

export const formatDeliveryMin = (min?: number | null) => {
  if (min === undefined || min === null) {
    return "";
  }
  return Number(min) === 0 ? "No minimum" : `${formatPrice(min)} min.`;
};

export const fulfillmentCookieName = "userFulfillmentPreference";

export const hasMedAndRecVariants = (variants?: Variant[]): boolean => {
  return (
    !!variants &&
    variants.some((v) => !!v.medical) &&
    variants.some((v) => !v.medical)
  );
};

export const filterVariantsByMedStatus = (
  isMedical: boolean,
  variants: Variant[],
) => {
  return variants.length ? variants.filter((v) => v.medical === isMedical) : [];
};

export const findInitialVariant = (variants?: Variant[]) =>
  variants && variants.length
    ? variants.find((v) => !!v.isInitialSelection) || variants[0]
    : null;

export const findLeaflyItemByType = (
  type: FulfillmentType | null,
  leaflyItems: LeaflyItems,
): LeaflyFulfillmentDetails | null => {
  if (!type || type === shippingFulfillmentType) {
    return null;
  }
  const items = leaflyItems[type];
  return items?.[0] ?? null;
};

/** NOTE: This function can be removed or simplified when the API response
 * is updated in future A/B tests. In the meantime, we need to transform the
 * response to find initial values and support multiple pickup/delivery items. */
export const transformFulfillmentResponse = (response: FulfillmentResponse) => {
  const leaflyItems = {
    delivery: response?.[0]?.details ? [response[0].details] : [],
    pickup: response?.[1]?.details ? [response[1].details] : [],
  };
  const shippingDetails = response?.[2]?.details ?? null;

  // find initial fulfillment type and leafly item values
  const type = response?.find((opt) => opt.isInitialSelection)?.type ?? null;
  const leaflyItem = findLeaflyItemByType(type, leaflyItems);
  const variant = findInitialVariant(leaflyItem?.variants);
  const variantsToShow = filterVariantsByMedStatus(
    !!variant?.medical,
    leaflyItem?.variants ?? [],
  );

  return {
    initialValues: {
      leaflyItem,
      type,
      variant,
      variantsToShow,
    },
    leaflyItems,
    shippingDetails,
  };
};
